import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuthentication: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresAuthentication: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
    meta: {
      requiresAuthentication: false
    }
  },
  {
    path: '/my-profile',
    name: 'my-profile',
    component: () => import('../views/user/MyProfile.vue'),
    meta: {
      requiresAuthentication: true
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/notifications/NotificationsList.vue'),
    meta: {
      requiresAuthentication: true
    }
  },
  {
    path: '/add-device',
    name: 'add-device',
    component: () => import('../views/devices/interaction/AddDevice.vue'),
    meta: {
      requiresAuthentication: true
    }
  },
  {
    path: '/device-history/:device_id',
    name: 'device-history',
    component: () => import('../views/devices/DeviceHistory.vue'),
    meta: {
      requiresAuthentication: true
    }
  },
  {
    path: '/device-settings/:device_id',
    name: 'device-settings',
    component: () => import('../views/devices/DeviceSettings.vue'),
    meta: {
      requiresAuthentication: true
    }
  },
  {
    path: '/shared-devices',
    name: 'shared-devices',
    component: () => import('../views/devices/interaction/ShareDevice.vue'),
    meta: {
      requiresAuthentication: true
    }
  },
  {
    path: '/device-info/:device_id',
    name: 'device-info',
    component: () => import('../views/devices/AboutDevice.vue'),
    meta: {
      requiresAuthentication: true
    }
  },
  {
    path: '/device-media/:device_id',
    name: 'device-media',
    component: () => import('../views/devices/DeviceMedia.vue'),
    meta: {
      requiresAuthentication: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
