
import { defineComponent } from "vue";
import { useUserDeviceStore } from "../stores/user_device";
import { useAuthStore } from "../stores/auth";
import { mapState, mapActions } from "pinia";
import moment from "moment-timezone";
import axios from "axios";

export default defineComponent({
  name: "AddDevice",
  components: {},
  data() {
    return {
      deviceToAddId: null,
      showModal: false,
      errors: [],
    };
  },
  computed: {
    // gives read access to this.userDevices
    ...mapState(useUserDeviceStore, ["userDevices"]),
    ...mapState(useAuthStore, ["user", "access_token", "userAuthenticated"]),
    ...mapActions(useAuthStore, ["LogOut", "LogIn"]),
  },
  // async mounted() {},
  methods: {
    async hasNewNotifications(device = null as any) {
      let newNotifications = false;
      if (device) {
        return device.alarms.some(
          (x: any) =>
            !x.viewed_by.length || !x.viewed_by.some((y: any) => y == this.user.userId)
        );
        // device.alarms.forEach((deviceAlarm: any) => {
        //   if (
        //     deviceAlarm.viewed_by.length <= 0 ||
        //     (deviceAlarm.viewed_by.length &&
        //       !deviceAlarm.viewed_by.some((x: any) => x == this.user.userId))
        //   ) {
        //     newNotifications = true;
        //   }
        // });
      } else {
        for (const device of this.user.devices) {
          newNotifications = await this.hasNewNotifications(device);
        }
      }
      return newNotifications;
    },
    timeConverter(UNIX_timestamp: number) {
      if (UNIX_timestamp) {
        let parsed = moment.utc(UNIX_timestamp, "YYYYMMDDHHmmss");
        if (parsed.isValid()) {
          if (this.user.timezone)
            return (
              parsed.tz(this.user.timezone).format("DD.MM.YYYY") +
              " at " +
              parsed.tz(this.user.timezone).format("HH:mm:ss")
            );
          else return parsed.format("DD.MM.YYYY") + " at " + parsed.format("HH:mm:ss");
        } else return UNIX_timestamp;
      }
    },
  },
});
