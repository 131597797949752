
import { defineComponent } from "vue";
import { useUserDeviceStore } from "./stores/user_device";
import { useAuthStore } from "./stores/auth";
import { useConversionsStore } from "./stores/conversions";
import { mapState, mapWritableState, mapActions } from "pinia";
import NavigationComponent from "./components/NavigationComponent.vue";
import BottomAppNavigation from "./components/BottomAppNavigation.vue";
import LoadingAnimation from "./components/animations/LoadingAnimation.vue";
import axios from "axios";

import * as mqtt from "mqtt"; // import everything inside the mqtt module and give it the namespace "mqtt"

const myAxios = axios;

export default defineComponent({
  name: "HomeView",
  components: { NavigationComponent, BottomAppNavigation, LoadingAnimation },
  data() {
    return {
      dateTime: null as any,
      devicesToPush: [],
      timeout: null,
      loading: true,
    };
  },
  computed: {
    // gives read access to this.userDevices
    ...mapWritableState(useUserDeviceStore, ["userDevices"]),
    ...mapWritableState(useAuthStore, ["user", "access_token", "client_connected"]),
    ...mapWritableState(useConversionsStore, ["conversions"]),
    ...mapState(useAuthStore, ["userAuthenticated"]),
    ...mapActions(useAuthStore, ["LogOut", "LogIn"]),
  },
  async mounted() {
    if (this.access_token) {
      await this.getConversions();
      await this.getProfile();
      this.changeLanguage();
    } else this.loading = false;
  },
  methods: {
    async getProfile() {
      myAxios.defaults.headers.common = {
        Authorization: `Bearer ${this.access_token}`,
      };
      await axios
        .get("/api/auth/profile")
        .then(async (response: any) => {
          if (response == false) this.signOut();
          else await this.connectMqttClient(response);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.signOut();
        });
    },
    async signOut() {
      this.LogOut;
      myAxios.defaults.headers.common = {};
      if (this.$route.path != "/login" && this.$route.path != "/register") {
        this.$router.push("/login");
        location.reload();
      }
    },
    changeLanguage() {
      if (this.user) this.$i18n.changeLanguage(this.user.locale);
    },
    async connectMqttClient(response: any) {
      // eslint-disable-next-line
      const vm: any = this;
      if (this.userAuthenticated) {
        // console.log("CONNNNECTS");
        this.client_connected = false;
        let client = mqtt.connect({
          hostname: process.env.VUE_APP_MQTT_HOST,
          port: 9001,
          username: process.env.VUE_APP_READ_WRITE_MQTT_USERNAME,
          password: process.env.VUE_APP_READ_WRITE_MQTT_PASSWORD,
          protocol: "mqtt",
        }); // create a client

        this.userDevices = [];
        var prevDevices: any = [];
        if (this.user && this.user.devices.length) {
          prevDevices = JSON.parse(JSON.stringify(this.user.devices));
        }
        this.user = response.data.user;
        this.user.admin_email = response.data.admin_email;
        if (prevDevices) {
          this.user.devices.forEach((device: any) => {
            device.lastUpdate = null;
            device.lastConnection = null;
            let foundDevice = prevDevices.find((x: any) => x.id == device.id);
            if (foundDevice && foundDevice.metadata) {
              device.metadata = foundDevice.metadata;
            }
          });
        }

        // console.log("BEFORE connect!!!!!");
        client.on("connect", function (error) {
          console.log("connect!!!!!");
          if (error) {
            console.log(error);
          }
          // console.log("connect!!!!! CLIENT");
          // userDevices.forEach((device: any) => {
          //   client.subscribe("+/" + device.id + "/+", function (err) {
          //     console.log(err);
          //   });
          // });
          client.subscribe("+/+/+/VAL", function (err) {
            console.log("VAL FLAG");
            if (err) {
              console.log(err);
            }
          });
          client.subscribe("+/+/+/APP", function (err) {
            console.log("APP FLAG");
            if (err) {
              console.log(err);
            }
          });
          client.subscribe("+/+/+", function (err) {
            console.log(err);
          });
        });
        client.on("message", function (topic: string, message: object) {
          if (!vm.client_connected) vm.client_connected = true;
          if (!vm.userAuthenticated) {
            vm.client_connected = false;
            client.end();
          }

          if (topic.split("/")[topic.split("/").length - 1] === "APP") {
            vm.deviceFlagUpdated(topic, message.toString());
          } else if (topic.split("/")[topic.split("/").length - 1] === "ALARM") {
            vm.deviceAlarmUpdated(topic, message.toString());
          } else if (topic.split("/")[topic.split("/").length - 1] === "VAL") {
            vm.deviceValuesUpdated(topic, message.toString());
          } else {
            vm.deviceParameterUpdated(topic, message.toString());
          }
        });

        // client.on("error", function (err) {
        //   console.log("erroRR");
        //   console.log(err);
        //   client.end();
        // });

        await this.getLatestBrokerData();
        await this.getDeviceAlarms();
        var userDevices: any = response.data.user.devices;

        if (userDevices.length) {
          userDevices.forEach(function (device: any) {
            ["PAR", "SET", "ACT", "CALC"].forEach(function (element) {
              if (device[element]) {
                device[element].forEach(async function (par: any) {
                  let splitTopic: any = [device.type, device.id, element];
                  let splitMessage: any = [
                    par.id
                      .split("_")
                      .slice(0, par.id.split("_").length - 1)
                      .join("_"),
                    par.id.split("_")[par.id.split("_").length - 1],
                    null,
                    par.value ?? null,
                    0,
                  ];

                  await vm.tryPushDeviceParameter(
                    splitTopic,
                    splitMessage,
                    par.locale_title
                      ? typeof par.locale_title === "string" ||
                        par.locale_title instanceof String
                        ? JSON.parse(par.locale_title)
                        : par.locale_title
                      : ({ en: null, slo: null })
                  );
                });
              }
            });
          });
        }
        //  else this.loading = false;
      }
    },

    async getDeviceAlarms() {
      this.user.devices.forEach((userDevice: any) => {
        if (!userDevice.alarms) userDevice.alarms = [];
      });
      let userDeviceIds = this.user.devices.map((x: any) => x.id);
      await axios
        .post("/api/device-alarms/get-alarms-for-devices", userDeviceIds)
        .then(async (response: any) => {
          response.data.forEach((element: any) => {
            let userDevice = this.user.devices.find((x: any) => x.id == element._id);
            userDevice.alarms = element.alarms;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getConversions() {
      await axios
        .get("/api/conversions/index")
        .then(async (response: any) => {
          this.conversions = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getLatestBrokerData() {
      await axios.get("/api/latest-broker-data/all").then((response) => {
        response.data.forEach((element: any) => {
          let historyDevice = this.user.devices.find(
            (x: any) => x.id == element.device_id
          );
          if (historyDevice) {
            if (
              element.type == "PAR" &&
              element &&
              (!historyDevice.lastUpdate ||
                parseInt(historyDevice.lastUpdate) < parseInt(element.timestamp))
            )
              historyDevice.lastUpdate = element.timestamp;

            if (
              element &&
              (!historyDevice.lastConnection ||
                parseInt(historyDevice.lastConnection) < parseInt(element.timestamp))
            )
              historyDevice.lastConnection = element.timestamp;
          }
        });
      });
    },
    deviceFlagUpdated(topic: any, message: any) {
      const splitTopic: any = topic.split("/");
      let userDevice = this.user.devices.find((x: any) => x.id == splitTopic[1]);
      if (userDevice) {
        userDevice.last_messages
          ? (userDevice.last_messages[splitTopic[2]] = message.toString())
          : (userDevice.last_messages = { [splitTopic[2]]: message.toString() });

        // We split the message on underscores, while ignoring underscores inside quotes
        // let splitMessage: any = message.toString().split(/_(?=(?:(?:[^"]*"){2})*[^"]*$)/);
        let splitMessage: any = message.toString().split("*");

        if (!userDevice.waiting_for_broker_confirmation) {
          userDevice.waiting_for_broker_confirmation = {
            PAR: [false, 0],
            ACT: [false, 0],
            SET: [false, 0],
          };
        }
        if (parseInt(splitMessage[1]) == 0) {
          userDevice.waiting_for_broker_confirmation[splitTopic[2]] = [
            false,
            splitMessage[0],
          ];
          this.allDeviceSubtypeParametersUpdated(userDevice, splitTopic[2]);
        } else if (parseInt(splitMessage[1]) == 1)
          userDevice.waiting_for_broker_confirmation[splitTopic[2]] = [
            true,
            splitMessage[0],
          ];

        let updating = splitMessage[1] == 1;
        let timestamp = splitMessage[0];
        let counter = 0;
        var formattedMessage = [] as any;
        this.allDeviceSubtypeParametersUpdated(userDevice, splitTopic[2]);
        splitMessage.splice(0, 2);
        splitMessage.forEach((message: any) => {
          let splitMsg = message.split(/_(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          splitMsg.forEach((element: any, index: number) => {
            if (counter == 2) formattedMessage.push(timestamp);
            formattedMessage.push(element);
            if (index == splitMsg.length - 1) {
              this.tryPushDeviceParameter(
                splitTopic,
                formattedMessage,
                null,
                true,
                updating
              );
              formattedMessage = [];
              counter = 0;
            } else counter++;
          });
        });
      }
    },
    allDeviceSubtypeParametersUpdated(device: any, subtypeId: any) {
      if (device[subtypeId]) {
        device[subtypeId].forEach((parameter: any) => {
          parameter.updating = false;
        });
      }
    },
    deviceAlarmUpdated(topic: any, message: any) {
      let splitMessage: any = message.toString().split("*");
      if (splitMessage[1] == 1) {
        const splitTopic: any = topic.split("/");
        let timestamp = splitMessage[0];
        let userDevice = this.user.devices.find((x: any) => x.id == splitTopic[1]);
        if (userDevice.alarms) {
          const existingAlarmIndex = userDevice.alarms.findIndex(
            (x: any) => x.timestamp == timestamp
          );
          if (existingAlarmIndex === -1) {
            userDevice.alarms.push({
              timestamp: timestamp,
              value: splitMessage[2],
              viewed_by: [],
            });
          } else userDevice.alarms[existingAlarmIndex].value = splitMessage[2];
        } else
          userDevice.alarms = [
            { timestamp: timestamp, value: splitMessage[2], viewed_by: [] },
          ];
      }
    },
    deviceParameterUpdated(topic: any, message: any) {
      const splitTopic: any = topic.split("/");

      let userDevice = this.user.devices.find((x: any) => x.id == splitTopic[1]);
      if (splitTopic.length == 3 && userDevice) {
        let splitMessage: any = message.toString().split("*");

        // this.lastUpdate = splitMessage[0];
        let timestamp = splitMessage[0];
        let counter = 0;
        var formattedMessage = [] as any;
        splitMessage.shift();
        splitMessage.forEach((message: any) => {
          let splitMsg = message.split(/_(?=(?:(?:[^"]*"){2})*[^"]*$)/);

          splitMsg.forEach((element: any, index: number) => {
            if (counter == 2) formattedMessage.push(timestamp);

            formattedMessage.push(element);

            if (index == splitMsg.length - 1) {
              this.tryPushDeviceParameter(splitTopic, formattedMessage, null, true);
              formattedMessage = [];
              counter = 0;
            } else counter++;
          });
        });
      }
      //  else this.loading = false;
    },

    deviceValuesUpdated(topic: any, message: any) {
      const splitTopic: any = topic.split("/");

      let userDevice = this.user.devices.find((x: any) => x.id == splitTopic[1]);
      if (userDevice) {
        let splitMessage: any = message
          .toString()
          .split("*")
          .filter((x: any) => x != "");

        // if (!userDevice.availableDeviceParameters)
        userDevice.availableDeviceParameters = {};

        splitMessage.forEach((message: any) => {
          let splitMsg = message.split(/_(?=(?:(?:[^"]*"){2})*[^"]*$)/);

          userDevice.availableDeviceParameters = {
            ...userDevice.availableDeviceParameters,
            ...{
              [`${splitMsg[0].replace("&", "")}_${splitMsg[1]}`]: splitMsg[
                splitMsg.length - 1
              ],
            },
          };
        });
      }
    },

    setPush() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.devicesToPush.forEach(async (device: any) => {
          await this.pushDeviceParameter(
            device[0],
            device[1],
            device[2],
            device[3],
            device[4]
          );
        });
        this.devicesToPush = [];
        // this.loading = false;
      }, 50);
    },

    async tryPushDeviceParameter(
      splitTopic: any,
      splitMessage: any,
      friendlyName = null as string | null,
      fromBroker = false,
      parameterUpdating = null
    ) {
      this.devicesToPush.push([
        splitTopic,
        splitMessage,
        friendlyName,
        fromBroker,
        parameterUpdating,
      ]);
      return this.setPush();
    },
    async pushDeviceParameter(
      splitTopic: any,
      splitMessage: any,
      friendlyName = null as any,
      fromBroker = false,
      parameterUpdating = null
    ) {
      if (!friendlyName) {
        friendlyName = { en: null, slo: null };
      }
      let historyDevice = this.user.devices.find((x: any) => x.id == splitTopic[1]);

      if (historyDevice) {
        if (!historyDevice.availableDeviceParameters)
          historyDevice.availableDeviceParameters = {};
        if (!historyDevice.friendly_name) {
          historyDevice.friendly_name = splitTopic[1];
        }
        if (
          fromBroker &&
          splitTopic[2] == "PAR" &&
          splitMessage[2] &&
          (!historyDevice.lastUpdate ||
            parseInt(historyDevice.lastUpdate) < parseInt(splitMessage[2]))
        )
          historyDevice.lastUpdate = splitMessage[2];

        if (
          fromBroker &&
          splitMessage[2] &&
          (!historyDevice.lastConnection ||
            parseInt(historyDevice.lastConnection) < parseInt(splitMessage[2]))
        )
          historyDevice.lastConnection = splitMessage[2];

        var subtype: any = false;
        if (historyDevice[splitTopic[2]]) subtype = historyDevice[splitTopic[2]];

        let firstParLetter = splitMessage[0].charAt(0);
        let parameterHidden = splitMessage[0].includes("/");
        let msg0 = splitMessage[0].replace("&", "").replace("/", "");
        if (subtype) {
          var parameter = subtype.find((x: any) =>
            splitMessage[1] != null
              ? x.id &&
                x.id.split("_").slice(0, -1).join("_") +
                  "_" +
                  x.id.split("_")[x.id.split("_").length - 1] ==
                  msg0 + "_" + splitMessage[1]
              : x.id && x.id == msg0
          );

          if (parameter && parameter.id) {
            if (
              !parameter.last_update ||
              parseInt(parameter.last_update) <= parseInt(splitMessage[2])
            ) {
              parameter.hidden = parameterHidden;
              if (!parameter.friendly_name) parameter.friendly_name = friendlyName;
              // if (!parameter.display_order) {
              //   parameter.display_order =
              //     splitTopic[2] == "PAR"
              //       ? subtype.length - subtype.findIndex((x: any) => x.id == parameter.id)
              //       : splitTopic[2] == "ACT"
              //       ? historyDevice["PAR"].length +
              //         subtype.length -
              //         subtype.findIndex((x: any) => x.id == parameter.id)
              //       : splitTopic[2] == "SET"
              //       ? subtype.length - subtype.findIndex((x: any) => x.id == parameter.id)
              //       : 4000;
              // }
              if (friendlyName[this.user.locale]) parameter.friendly_name = friendlyName;
              if (splitMessage[2] != null) parameter.last_update = splitMessage[2];

              parameter.can_multiselect = firstParLetter === "&";

              if (parameterUpdating !== null && splitTopic[3] == "APP") {
                if (
                  !parameter.last_update ||
                  parseInt(splitMessage[2]) >= parseInt(parameter.last_update)
                )
                  historyDevice[parameter.type].find(
                    (y: any) => y.id == parameter.id
                  ).updating = parameterUpdating;
                else
                  historyDevice[parameter.type].find(
                    (y: any) => y.id == parameter.id
                  ).updating = parameter.updating == parameterUpdating; //; parameter.updating = parameter.updating == 1;
              }
              return (parameter.value = splitMessage[3]);
            }
          } else if (!fromBroker) {
            const userParameter = this.user.devices.find(
              (x: any) => x.id == splitTopic[1]
            );
            const final = userParameter[splitTopic[2]].find(
              (x: any) =>
                x.id == (splitMessage[1] != null ? `${msg0}_${splitMessage[1]}` : msg0)
            );
            return subtype.push({
              id: splitMessage[1] != null ? `${msg0}_${splitMessage[1]}` : msg0,
              value: splitMessage[3],
              friendly_name: friendlyName,
              subtype: splitTopic[2],
              selected_group: final ? final.default_group : null,
              permission: final ? final.permission : null,
              default_unit: final ? final.default_unit : null,
              selected_unit: final ? final.selected_unit : null,
              last_update: splitMessage[2],
              updating: parameterUpdating !== null ? parameterUpdating : false,
              can_multiselect: firstParLetter === "&",
              hidden: parameterHidden,
              display_order: null,
              // final && final.display_order
              //   ? final.display_order
              //   : userParameter["PAR"].length +
              //     userParameter["ACT"].length +
              //     userParameter["SET"].length,
            });
          }
        } else if (!fromBroker) {
          const userParameter = this.user.devices.find((x: any) => x.id == splitTopic[1]);
          const final = userParameter[splitTopic[2]].find(
            (x: any) =>
              x.id == (splitMessage[1] != null ? `${msg0}_${splitMessage[1]}` : msg0)
          );

          return historyDevice[splitTopic[2]].push({
            id: splitMessage[1] != null ? `${msg0}_${splitMessage[1]}` : msg0,
            value: splitMessage[3],
            friendly_name: friendlyName,
            subtype: splitTopic[2],
            selected_group: final ? final.default_group : null,
            permission: final ? final.permission : null,
            default_unit: final ? final.default_unit : null,
            selected_unit: final ? final.selected_unit : null,
            last_update: splitMessage[2],
            updating: parameterUpdating !== null ? parameterUpdating : false,
            can_multiselect: firstParLetter === "&",
            hidden: parameterHidden,
            display_order: null,
            // final && final.display_order
            //   ? final.display_order
            //   : userParameter["PAR"].length +
            //     userParameter["ACT"].length +
            //     userParameter["SET"].length,
          });
        }
      } else if (!fromBroker) {
        // const group = this.user.devices.find((x: any) => x.id == splitTopic[1]).group;
        const userParameter = this.user.devices.find((x: any) => x.id == splitTopic[1]);
        const final = userParameter[splitTopic[2]].find(
          (x: any) =>
            x.id ==
            (splitMessage[1] != null
              ? `${splitMessage[0]}_${splitMessage[1]}`
              : splitMessage[0])
        );
        let parameterDataToPush = {
          id:
            splitMessage[1] != null
              ? `${splitMessage[0]}_${splitMessage[1]}`
              : splitMessage[0],
          value: splitMessage[3],
          friendly_name: friendlyName,
          subtype: splitTopic[2],
          selected_group: final ? final.default_group : null,
          permission: final ? final.permission : null,
          default_unit: final ? final.default_unit : null,
          selected_unit: final ? final.selected_unit : null,
          last_update: splitMessage[2],
          updating: parameterUpdating,
          hidden: true,
          display_order: null,
          // userParameter["PAR"].length +
          // userParameter["ACT"].length +
          // userParameter["SET"].length,
        };

        return this.user.devices.push({
          id: splitTopic[1],
          friendly_name: splitTopic[1],
          type: splitTopic[0],
          lastUpdate: null,
          PAR: splitTopic[2] == "PAR" ? parameterDataToPush : [],
          ACT: splitTopic[2] == "ACT" ? parameterDataToPush : [],
          SET: splitTopic[2] == "SET" ? parameterDataToPush : [],
          CALC: splitTopic[2] == "CALC" ? parameterDataToPush : [],
        });
      }
    },
  },
  watch: {
    "$route.path": {
      handler: function () {
        if (!this.loading && !this.userAuthenticated) this.getProfile();
      },
      deep: true,
      immediate: true,
    },
    "user.locale": {
      handler: function () {
        if (!this.loading) this.changeLanguage();
      },
      deep: true,
      immediate: true,
    },
  },
});
