import { defineStore } from 'pinia'

// the first argument is a unique id of the store across your application
export const useUserDeviceStore = defineStore('device', {
  state: () => {
    return {
      // lastUpdate: null as string | null,
      // all these properties will have their type inferred automatically
      userDevices: [
        {
          id: "" as string | undefined,
          type: "" as string | undefined,
          lastUpdate: "" as string | undefined,
          subtypes: [
            {
              id: "" as string | undefined,
              friendly_name: null as string | null,
              parameters: [
                {
                  id: "" as string | undefined,
                  value: "" as string | undefined,
                  friendly_name: null as string | null,
                },
              ],
            },
          ],
        },
      ],
    }
  },
})