
import { defineComponent } from "vue";
import { useConversionsStore } from "../stores/conversions";
import { useUserDeviceStore } from "../stores/user_device";
import { useAuthStore } from "../stores/auth";
import { mapState, mapWritableState } from "pinia";

export default defineComponent({
  name: "ModalComponent",
  props: ["is_danger_button"],
  computed: {
    // gives read access to this.userDevices
    // ...mapState(useUserDeviceStore, ["userDevices", "lastUpdate"]),
    // ...mapState(useConversionsStore, ["conversions"]),
    // ...mapWritableState(useAuthStore, ["user"]),
    // ...mapState(useAuthStore, ["userAuthenticated"]),
  },
  methods: {
    // parameterName(parameter: any, device: any) {
    //   let parId = parameter.id.split("_")[parameter.id.split("_").length - 1];
    //   console.log("parameter");
    //   console.log(parameter);
    //   if (parameter && this.user && parameter.friendly_name[this.user.locale]) {
    //     let foundPar = device.subtypes
    //       .find((x: any) => x.id == parameter.subtype)
    //       .parameters.some(
    //         (x: any) =>
    //           x.id != parameter.id &&
    //           x.friendly_name[this.user.locale] ==
    //             parameter.friendly_name[this.user.locale]
    //       );
    //     if (parId > 0 && foundPar) {
    //       return (
    //         parameter.friendly_name[this.user.locale] +
    //         " " +
    //         parameter.id.split("_")[parameter.id.split("_").length - 1]
    //       );
    //     }
    //     return parameter.friendly_name[this.user.locale];
    //   } else return parameter.id;
    // },
  },
});
