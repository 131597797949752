import { defineStore } from 'pinia'

// the first argument is a unique id of the store across your application
export const useConversionsStore = defineStore('conversions', {
  state: () => {
    return {
      conversions: {} as any,
    }
  },
  persist: true
})