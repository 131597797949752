
import axios from "axios";
import moment from "moment-timezone";
import { defineComponent } from "vue";
import ModalComponent from "../components/ModalComponent.vue";
import ModalCVPComponent from "../components/ModalCVPComponent.vue";
import LoadingAnimationSmall from "../components/animations/LoadingAnimationSmall.vue";
import { useUserDeviceStore } from "../stores/user_device";
import { useAuthStore } from "../stores/auth";
import { mapState, mapWritableState } from "pinia";
import { useConversionsStore } from "../stores/conversions";

export default defineComponent({
  name: "HomeView",
  components: {
    ModalComponent,
    ModalCVPComponent,
    LoadingAnimationSmall,
  },
  data() {
    return {
      showModal: false,
      showCVPModal: false,
      newParameterName: null,
      updatingParameter: {} as any,
      updatingDeviceGroup: {} as any,
      updatingCVP: {} as any,
      row: null,
      draggingParameter: {} as any,
      originalDisplayOrder: null as any,
      originalGroup: null as any,
      dragoverId: null as any,
      lastDraggedOver: {} as any,
      displayDisableLoggingWarning: false as boolean,
    };
  },
  computed: {
    // gives read access to this.userDevices
    ...mapState(useUserDeviceStore, ["userDevices"]),
    ...mapState(useConversionsStore, ["conversions"]),
    ...mapWritableState(useAuthStore, ["user", "client_connected"]),
    ...mapState(useAuthStore, ["userAuthenticated"]),
  },
  async created() {
    // await this.maxSizeTexarea();
    // this.user.devices.forEach((element: any) => {
    //   if (element.metadata) element.metadata.showDropdown = false;
    // });
    this.$nextTick(() => {
      let elements = document.querySelectorAll(".device-id");

      for (let i = 0; i < elements.length; i++) {
        this.maxSizeTexarea(elements[i]);
      }
    });
  },
  methods: {
    refocus(id: string) {
      this.$nextTick(() => {
        let el = document.getElementById(id) as HTMLInputElement;
        if (el) {
          el.focus();
          var val = el.value; //store the value of the element
          el.value = ""; //clear the value of the element
          el.value = val; //set that value back.  }
        }
      });
    },
    updateDeviceData(device: any) {
      device.updating_data = true;

      let copiedDevice = JSON.parse(JSON.stringify(device));

      delete copiedDevice.alarms;
      delete copiedDevice.availableDeviceParameters;
      delete copiedDevice.metadata;
      delete copiedDevice.updating_data;
      delete copiedDevice.updatingGroupName;

      let params = new URLSearchParams();
      params.append("user_id", this.user.userId);
      params.append("device", JSON.stringify(copiedDevice));
      axios.post("/api/users/update-user-device", params).then(async (response) => {
        let data = response.data;
        
        let foundDevice = data.devices.find((x: any) => x.id == device.id);
        device.PAR = foundDevice.PAR;
        device.SET = foundDevice.SET;
        device.ACT = foundDevice.ACT;
        device.updating_data = false;
      });
    },
    updateDeviceParameter(device: any, parameter: any) {
      let foundParameter = this.user.devices.find((x: any) => x.id == device.id);
      foundParameter = foundParameter[parameter.type].find(
        (x: any) => x.id == parameter.id
      );
      if (parameter.type == "CALC" || (foundParameter && foundParameter.type == "CALC")) {
        this.updatingCVP = {
          creating: false,
          id: parameter ? parameter.id : null,
          name: parameter.friendly_name[this.user.locale],
          device: device,
          subtype: "CALC",
          data_type: foundParameter.data_type ? foundParameter.data_type : null,
          parameterId: parameter.id,
          newValue:
            foundParameter.data_type == "bool"
              ? parameter.value == "1"
              : JSON.parse(JSON.stringify(parameter.value)),
          oldValue: JSON.parse(JSON.stringify(parameter.value)),
          group: parameter.group ? parameter.group : null,
          groups: device.groups,
          permission: parameter.permission,
          canEdit: this.canEdit(device),
          errors: [],
        };
        this.showCVPModal = true;
      } else {
        if (
          !device[parameter.type].find((x: any) => x.id == parameter.id).selected_unit &&
          parameter.default_unit
        ) {
          device[parameter.type].find((x: any) => x.id == parameter.id).selected_unit =
            parameter.default_unit;
        }
        if (
          !parameter.value &&
          foundParameter.data_type &&
          foundParameter.data_type.split("(")[0] == "multiselect"
        ) {
          parameter.value = [];
        }
        let value = null;
        if (typeof parameter.value === "string" || parameter.value instanceof String) {
          try {
            value = JSON.parse(parameter.value);
          } catch (error) {
            value = parameter.value;
          }
        } else value = parameter.value;

        this.updatingParameter = {
          friendly_name: parameter.friendly_name,
          friendlyName:
            parameter.friendly_name && parameter.friendly_name[this.user.locale]
              ? parameter.friendly_name[this.user.locale]
              : null,
          deviceId: device.id,
          type: parameter.type,
          data_type: foundParameter.data_type,
          parameterId: parameter.id,
          id: parameter.id,
          enable_logging: parameter.enable_logging ?? false,
          enable_logging_old: parameter.enable_logging ?? false,
          newValue:
            foundParameter.data_type == "bool"
              ? parameter.value == "1"
              : JSON.parse(JSON.stringify(value)),
          oldValue:
            foundParameter.data_type == "bool"
              ? parameter.value == "1"
              : JSON.parse(JSON.stringify(value)),
          availableParameters: [],
          default_value: foundParameter.default_value,
          group: parameter.group ? parameter.group : null,
          groups: device.groups,
          permission: parameter.permission,
          canEdit: this.canEdit(device),
          errors: [],
        };
        this.updatingParameter.availableParameters =
          foundParameter.data_type &&
          foundParameter.data_type.split("(")[0] == "multiselect"
            ? this.getAvailableParameters(
                device.id,
                parameter.data_type.split("(")[1].split(")")[0]
              )
            : [];
        this.showModal = true;
      }
    },
    createCVP(device: any, parameter: any = null) {
      this.updatingCVP = {
        creating: true,
        id: parameter ? parameter.id : null,
        name: "",
        device: device,
        subtype: "CALC",
        data_type: null,
        parameterId: null,
        newValue: "",
        oldValue: null,
        group: null,
        groups: device.groups,
        permission: "R/W",
        canEdit: this.canEdit(device),
        errors: [],
      };
      this.showCVPModal = true;
    },
    async submitCVP() {
      this.updatingCVP.errors = [];
      if (this.updatingCVP.name && this.updatingCVP.newValue) {
        let myDevice = this.user.devices.find(
          (x: any) => x.id == this.updatingCVP.device.id
        );
        if (myDevice) {
          let alreadyExisted = false;
          if (myDevice["CALC"]) {
            myDevice["CALC"].forEach((calcElement: any) => {
              if (calcElement.id == this.updatingCVP.id) {
                calcElement.locale_title = JSON.stringify({
                  slo: this.updatingCVP.name,
                  en: this.updatingCVP.name,
                });
                calcElement.group = this.updatingCVP.group;
                calcElement.value = this.updatingCVP.newValue;
                alreadyExisted = true;
              }
            });
          } else {
            alreadyExisted = true;
            myDevice["CALC"] = [
              {
                id: "XAXAXAXA_0",
                type: "CALC",
                dever: 0,
                // locale_title: this.updatingCVP.name,
                value: this.updatingCVP.newValue,
                locale_title: JSON.stringify({
                  en: this.updatingCVP.name,
                  slo: this.updatingCVP.name,
                }),
                data_type: "calc",
                permission: "R/W",
                group: this.updatingCVP.group ?? null,
                graph_type: "linija",
                default_unit: "-",
                units: [],
              },
            ];
          }
          if (alreadyExisted == false) {
            myDevice["CALC"].push({
              id: "XAXAXAXA_" + myDevice["CALC"].length,
              type: "CALC",
              display_order: 0,
              // locale_title: this.updatingCVP.name,
              value: this.updatingCVP.newValue,
              locale_title: JSON.stringify({
                en: this.updatingCVP.name,
                slo: this.updatingCVP.name,
              }),
              data_type: "calc",
              permission: "R/W",
              group: this.updatingCVP.group ?? null,
              graph_type: "linija",
              default_unit: "-",
              units: [],
            });
          }
          await this.updateDeviceData(myDevice);
          await this.$parent.$parent.getProfile();
          this.showCVPModal = false;
        }
      } else if (!this.updatingCVP.name) {
        this.updatingCVP.errors.push("The name field cannot be empty.");
      } else if (!this.updatingCVP.newValue) {
        this.updatingCVP.errors.push("The calculation field cannot be empty.");
      }
    },
    async removeCVP() {
      let myDevice = this.user.devices.find(
        (x: any) => x.id == this.updatingCVP.device.id
      );
      if (myDevice && myDevice["CALC"]) {
        myDevice["CALC"] = myDevice["CALC"].filter(
          (x: any) => x.id != this.updatingCVP.id
        );
      }
      await this.updateDeviceData(myDevice);
      this.showCVPModal = false;
    },
    async addGroup(device: any) {
      device.groups
        ? device.groups.push("New group " + device.groups.length)
        : (device.groups = ["New group 0"]);
      await this.updateDeviceData(device);
    },
    async updateDeviceGroup(device: any, index: number, focusout = false) {
      let groupToUpdate = device.groups[index];
      let updatedGroupName = device.updatingGroupName[index];

      device.groups[index] = updatedGroupName;
      device.groups.forEach((element: any, i: any) => {
        if (element == "") device.groups.splice(i, 1);
      });

      ["PAR", "ACT", "SET", "CALC"].forEach((type: any) => {
        if (device[type])
          device[type]
            .filter((x: any) => x.group == groupToUpdate)
            .forEach((element: any) => {
              element.group = updatedGroupName;
            });
      });
      if (focusout) {
        await this.updateDeviceData(device);
      }
    },
    parameterName(parameter: any, device: any) {
      return parameter &&
        this.user &&
        parameter.friendly_name &&
        parameter.friendly_name[this.user.locale]
        ? parameter.friendly_name[this.user.locale]
        : parameter && parameter.id
        ? parameter.id
        : parameter;
    },
    pushToEquation(value: any, isPar = false) {
      if (value)
        isPar
          ? (this.updatingCVP.newValue += `[${value}]`)
          : (this.updatingCVP.newValue += value);
    },
    parameterValue(device: any, parameter: any) {
      let foundDevice = this.user.devices.find((x: any) => x.id == device.id);

      let foundParameter = this.user.devices.find((x: any) => x.id == device.id);
      foundParameter = foundParameter[parameter.type].find(
        (x: any) => x.id == parameter.id
      );

      if (parameter.type == "CALC") {
        try {
          let resultString = "";
          let parFormulaLeftSplit = foundParameter.value
            .split("[")
            .filter(function (el: any) {
              return el != "";
            });

          for (const leftSplit of parFormulaLeftSplit) {
            let splitValue = leftSplit.split("]").filter(function (el: any) {
              return el != "";
            });

            let foundParam = device["PAR"].find((x: any) => x.id == splitValue[0]);

            if (foundParam) {
              if (foundParam.value) {
                resultString += parseFloat(foundParam.value);
              } else {
                resultString = "/";
                break;
              }
            }
            splitValue.shift();
            splitValue.forEach((element: any) => {
              resultString += element;
            });
          }

          try {
            if (resultString && resultString != "/") return eval(resultString).toFixed(2);
            else return resultString;
          } catch (error) {
            return "Fn. Err.";
          }
        } catch (error) {
          console.log(error);
          return "Gen. Err.";
        }
      }
      if (
        foundParameter.default_unit &&
        foundParameter.selected_unit &&
        foundParameter.default_unit.title != foundParameter.selected_unit.title
      ) {
        let conversion = this.conversions.find(
          (x: any) =>
            (x.from_unit_id == foundParameter.selected_unit.title &&
              x.to_unit_id == foundParameter.default_unit.title) ||
            (x.to_unit_id == foundParameter.selected_unit.title &&
              x.from_unit_id == foundParameter.default_unit.title)
        );
        // If a conversion exists for the selected/default unit
        if (conversion) {
          let formula = conversion.formula.toString();
          var x = null;
          if (parameter.value) x = parseFloat(parameter.value);
          else if (parameter.newValue) x = parseFloat(parameter.newValue);
          return eval(formula).toFixed(2);
        } else if (parameter.value) return parameter.value;
        else if (parameter.newValue) return parameter.newValue;
      } else if (
        foundParameter.data_type &&
        typeof foundParameter.data_type == "string" &&
        foundParameter.data_type.split("(")[0] == "object"
      ) {
        let parsedValues = [];

        if (
          foundDevice.availableDeviceParameters &&
          foundDevice.availableDeviceParameters[foundParameter.id]
        ) {
          try {
            parsedValues = JSON.parse(
              foundDevice.availableDeviceParameters[foundParameter.id]
            );
          } catch (error) {
            parsedValues = foundDevice.availableDeviceParameters[foundParameter.id];
          }
        }
        let rtrn = "/";

        if (parameter.value && typeof parsedValues[parameter.value] !== "undefined") {
          rtrn = parsedValues[parameter.value];
        } else if (
          parameter.newValue &&
          typeof parsedValues[parameter.newValue] !== "undefined"
        ) {
          rtrn = parsedValues[parameter.newValue];
        }

        return rtrn;
      } else {
        if (parameter.value != null) return parameter.value;
        else if (parameter.newValue != null) return parameter.newValue;
      }
    },
    getAvailableParameters(deviceId: any, subtype: string) {
      let device = this.user.devices.find((x: any) => x.id == deviceId);
      let multiselectParameters: any = [];
      ["PAR", "ACT", "SET"].forEach((element: any) => {
        let parsToPush = device[element].filter(
          (x: any) => x.data_type && x.data_type.split("(")[0] == "multiselect"
        );
        if (parsToPush.length) multiselectParameters = [parsToPush];
      });

      let availableParameters = device[subtype].filter(
        (x: any) =>
          x.data_type && x.data_type.split("(")[0] != "multiselect" && x.can_multiselect
      );

      multiselectParameters.forEach((element: any) => {
        if (element.value) {
          let val: any = null;
          if (typeof element.value === "string" || element.value instanceof String) {
            try {
              val = JSON.parse(element.value);
            } catch (error) {
              val = element.value;
            }
          } else val = element.value;
          val.forEach((par: any) => {
            const index = availableParameters
              .map(function (e: any) {
                return e.id;
              })
              .indexOf(par);
            // const index = availableParameters.indexOf((x: any) => x.id == par);
            if (index > -1) {
              availableParameters.splice(index, 1);
            }
          });
        }
      });

      let val = [];
      if (
        typeof this.updatingParameter.newValue === "string" ||
        this.updatingParameter.newValue instanceof String
      )
        val = JSON.parse(this.updatingParameter.newValue);
      else val = this.updatingParameter.newValue;

      val.forEach((par: any) => {
        const index = availableParameters
          .map(function (e: any) {
            return e.id;
          })
          .indexOf(par);

        if (index > -1) {
          availableParameters.splice(index, 1);
        }
      });
      return availableParameters;
    },
    timeConverter(UNIX_timestamp: number) {
      if (UNIX_timestamp) {
        let parsed = moment.utc(UNIX_timestamp, "YYYYMMDDHHmmss");
        if (parsed.isValid()) {
          if (this.user.timezone)
            return (
              parsed.tz(this.user.timezone).format("DD.MM.YYYY") +
              " at " +
              parsed.tz(this.user.timezone).format("HH:mm:ss")
            );
          else return parsed.format("DD.MM.YYYY") + " at " + parsed.format("HH:mm:ss");
        } else return UNIX_timestamp;
      } else return "";
    },
    openCloseDevice(device: any) {
      if (device.metadata) {
        device.metadata.openStatus == "open"
          ? (device.metadata.openStatus = "closed")
          : (device.metadata.openStatus = "open");
      } else
        device.metadata = {
          openStatus: "open",
          // showDropdown: false,
          removingParameter: false,
        };
    },
    // showHideDeviceMenu(device: any) {
    //   if (device.metadata) {
    //     device.metadata.showDropdown == true
    //       ? (device.metadata.showDropdown = false)
    //       : (device.metadata.showDropdown = true);
    //     device.metadata.removingParameter = false;
    //   } else
    //     device.metadata = {
    //       openStatus: "closed",
    //       showDropdown: true,
    //       removingParameter: false,
    //     };
    // },
    changeBooleanParameter() {
      this.updatingParameter.newValue =
        this.updatingParameter.newValue == "1" ? "0" : "1";
    },
    async setAlarmsAsViewed(device_id = null, timestamps = []) {
      let formData = new URLSearchParams();

      let devicesToUpdate: any = [];
      if (!device_id) {
        devicesToUpdate = this.user.devices.map((x: any) => x.id);
      } else {
        devicesToUpdate.push(device_id);
      }
      let timestampsToUpdate: any = timestamps;
      if (!timestampsToUpdate.length) {
        if (!device_id) {
          this.user.devices.forEach((device: any) => {
            device.alarms.forEach((alarm: any) => {
              if (
                !alarm.viewed_by.length ||
                !alarm.viewed_by.some((x: any) => x == this.user.userId)
              )
                timestampsToUpdate.push(alarm.timestamp);
            });
          });
        } else {
          this.user.devices
            .find((x: any) => x.id == device_id)
            .alarms.forEach((alarm: any) => {
              if (
                !alarm.viewed_by.length ||
                !alarm.viewed_by.some((x: any) => x == this.user.userId)
              )
                timestampsToUpdate.push(alarm.timestamp);
            });
        }
      }

      formData.append("device_ids", JSON.stringify(devicesToUpdate));
      formData.append("user_id", this.user.userId);
      formData.append("alarm_timestamps", JSON.stringify(timestampsToUpdate));
      await axios
        .post("/api/device-alarms/set-alarms-viewed", formData)
        .then(async (response) => {
          // location.reload();
          await this.getDeviceAlarms();
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.data.message) {
            this.errors.push(e.response.data.message);
          }
        });
    },

    async getDeviceAlarms() {
      this.user.devices.forEach((userDevice: any) => {
        if (!userDevice.alarms) userDevice.alarms = [];
      });
      let userDeviceIds = this.user.devices.map((x: any) => x.id);
      await axios
        .post("/api/device-alarms/get-alarms-for-devices", userDeviceIds)
        .then(async (response: any) => {
          response.data.forEach((element: any) => {
            let userDevice = this.user.devices.find((x: any) => x.id == element._id);
            userDevice.alarms = element.alarms;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    reducedParametersToDisplay(device: any, group = null as any) {
      let res: any = [];
      if (group === "") {
        return;
      }

      let groups: any = [];
      let nullGroup: any = 0;
      // console.log(group + "END");
      ["PAR", "ACT", "CALC"].forEach((subtype: any) => {
        if (device[subtype])
          device[subtype]
            .filter((x: any) => !x.hidden)
            .sort(this.compare)
            .forEach((parameter: any) => {
              if (!parameter.group) parameter.group = null;
              let pushToGroup = parameter.group;

              if (!this.originalDisplayOrder) {
                if (!pushToGroup) nullGroup++;
                else if (groups[pushToGroup]) groups[pushToGroup]++;
                else groups[pushToGroup] = 1;

                parameter.display_order = !pushToGroup ? nullGroup : groups[pushToGroup];
              }

              if (
                !device.groups ||
                !device.groups.includes(
                  parameter.group ? parameter.group.trim() : parameter.group
                )
              ) {
                pushToGroup = null;
              }
              // parameter.subtype = subtype.id;
              let found = res.find((x: any) => x.group === pushToGroup);
              if (found) {
                found.data.push(parameter);
              } else {
                res.push({ group: pushToGroup, data: [parameter] });
              }
            });
      });

      if (group) {
        let found = res.find((x: any) => x.group === group);

        return found ? found.data : [];
      } else if (res.length) {
        return res
          .find(
            (x: any) =>
              x.group === null || (device.groups && !device.groups.includes(x.group))
          )
          .data.sort(this.compare);
      } else return;
    },
    reducedSettingsToDisplay(device: any) {
      let res: any = [];
      // if (group === "") {
      //   return;
      // }
      if (device["SET"])
        device["SET"]
          .filter((x: any) => !x.hidden)
          .forEach((parameter: any) => {
            if (!parameter.group) parameter.group = null;
            let pushToGroup = parameter.group;
            if (!device.groups || !device.groups.includes(parameter.group)) {
              pushToGroup = null;
            }
            // parameter.subtype = subtype.id;
            let found = res.find((x: any) => x.group === pushToGroup);
            if (found) {
              found.data.push(parameter);
            } else {
              res.push({ group: pushToGroup, data: [parameter] });
            }
          });

      // if (group) {
      //   let found = res.find((x: any) => x.group === group);

      //   return found ? found.data : [];
      // } else
      if (res.length) {
        return res.find(
          (x: any) =>
            x.group === null || (device.groups && !device.groups.includes(x.group))
        ).data;
      } else return;
    },
    compare(a: any, b: any) {
      if (a && b) {
        if (a.display_order < b.display_order) {
          return -1;
        }
        if (a.display_order > b.display_order) {
          return 1;
        }
      }
      return 0;
    },
    enableDisableLogging(updatingParameter: any, forceSet = false) {
      if (updatingParameter.canEdit) {
        if (
          forceSet == false &&
          updatingParameter.enable_logging_old == true &&
          updatingParameter.enable_logging == true
        ) {
          this.displayDisableLoggingWarning = true;
        } else {
          updatingParameter.enable_logging = !updatingParameter.enable_logging;
          this.displayDisableLoggingWarning = false;
        }
      }
    },
    async updateParameter() {
      if (
        this.updatingParameter.data_type == "bool" &&
        (this.updatingParameter.oldValue == null ||
          typeof this.updatingParameter.oldValue == undefined)
      ) {
        this.updatingParameter.oldValue = false;
      }

      let deviceIndex = this.user.devices.findIndex(
        (x: any) => x.id == this.updatingParameter.deviceId
      );

      let parameterIndex = this.user.devices[deviceIndex][
        this.updatingParameter.type
      ].findIndex((x: any) => x.id == this.updatingParameter.parameterId);

      this.user.devices[deviceIndex][this.updatingParameter.type][
        parameterIndex
      ].friendly_name[this.user.locale] = this.updatingParameter.friendlyName;

      let userParam = this.user.devices[deviceIndex][this.updatingParameter.type][
        parameterIndex
      ];

      let localeTitle = null;
      if (
        typeof userParam.locale_title === "string" ||
        userParam.locale_title instanceof String
      ) {
        localeTitle = JSON.parse(userParam.locale_title);
      } else localeTitle = userParam.locale_title;

      if(localeTitle == null){
        localeTitle = {en: null, slo: null};
      }

      localeTitle[this.user.locale] = this.updatingParameter.friendlyName;
      userParam.locale_title = localeTitle;

      userParam.group = this.updatingParameter.group;

      if (
        (Array.isArray(this.updatingParameter.newValue) &&
          !this.arraysEqual(
            this.updatingParameter.newValue,
            this.updatingParameter.oldValue
          )) ||
        (!Array.isArray(this.updatingParameter.newValue) &&
          this.updatingParameter.newValue != this.updatingParameter.oldValue)
      ) {
        userParam.value = this.updatingParameter.newValue;

        await this.updateWritableParameter();
      }

      this.user.devices[deviceIndex][this.updatingParameter.type][
        parameterIndex
      ].enable_logging = this.updatingParameter.enable_logging;

      await this.updateDeviceData(this.user.devices[deviceIndex]);
      this.displayDisableLoggingWarning = false;
      this.showModal = false;
    },

    deviceNotifications(device: any) {
      let returnAlarms: any = [];
      if (device.alarms) {
        device.alarms.forEach((element: any) => {
          let foundAlarmIndex = returnAlarms.findIndex(
            (x: any) => x.value == element.value
          );
          if (foundAlarmIndex === -1) {
            returnAlarms.push({
              timestamp: element.timestamp,
              value: element.value,
              viewed_by: element.viewed_by,
              count: 1,
            });
          } else {
            returnAlarms[foundAlarmIndex].count++;
            if (parseInt(element.timestamp) > returnAlarms[foundAlarmIndex].timestamp) {
              returnAlarms[foundAlarmIndex].timestamp = element.timestamp;
              returnAlarms[foundAlarmIndex].viewed_by = element.viewed_by ?? null;
            }
          }
        });
      }
      return returnAlarms.sort(function (a: any, b: any) {
        return b.timestamp - a.timestamp;
      });
    },
    renameDevice(device: any) {
      let inputEl = document.getElementById(
        "device-name-input" + device.id
      ) as HTMLInputElement;
      if (inputEl) {
        let val = inputEl.value;
        inputEl.value = "";
        inputEl.focus();
        inputEl.value = val;
      }
    },
    async removeDevice(device: any) {
      let params = new URLSearchParams();
      params.append("user_id", this.user.userId);
      params.append("device_id", device.id);
      await axios.post("/api/users/detach-device", params).then(async (response) => {
        this.user.devices = this.user.devices.filter((x: any) => x.id != device.id);
      });
    },

    async updateWritableParameter() {
      let updatedParameter = JSON.parse(JSON.stringify(this.updatingParameter));
      let params = new URLSearchParams();

      params.append("user_id", this.user.userId);
      params.append(
        "last_update_string",
        this.user.devices.find((x: any) => x.id == updatedParameter.deviceId)
          .last_messages[updatedParameter.type]
      );

      params.append("updated_parameter", JSON.stringify(updatedParameter));
      return await axios
        .post("/api/users/send-parameter-to-broker", params)
        .then(async (response) => {
          let device = this.user.devices.find(
            (x: any) => x.id == updatedParameter.deviceId
          );
          device.waiting_for_broker_confirmation[updatedParameter.type][0] = true;
          // let deviceParameter = device[updatedParameter.type].find(
          //   (x: any) => x.id == updatedParameter.parameterId
          // );

          device[updatedParameter.type].find(
            (y: any) => y.id == updatedParameter.parameterId
          ).updating = true;

          device.last_messages
            ? (device.last_messages[updatedParameter.type] = response.data)
            : (device.last_messages = { [updatedParameter.type]: response.data });

          // await this.$parent.$parent.getProfile();
        });
    },

    selectParameter(parameter_id: string, type: string) {
      if (this.updatingParameter.canEdit) {
        if (
          !this.updatingParameter.newValue ||
          !this.updatingParameter.newValue.includes(parameter_id)
        ) {
          this.updatingParameter.newValue.push(parameter_id);
          let paramIndex = this.updatingParameter.availableParameters.findIndex(
            (x: any) => x.id == parameter_id
          );
          if (paramIndex > -1) {
            this.updatingParameter.availableParameters.splice(paramIndex, 1);
          }
        }
      }
    },
    removeSelectedParameter(parameter_id: string, type: string) {
      if (this.updatingParameter.canEdit) {
        let device = this.user.devices.find(
          (x: any) => x.id == this.updatingParameter.deviceId
        );

        this.updatingParameter.newValue = JSON.parse(
          JSON.stringify(
            this.updatingParameter.newValue.filter((x: any) => x != parameter_id)
          )
        );

        this.updatingParameter.availableParameters.push(
          device[type].find((x: any) => x.id == parameter_id)
        );
      }
    },
    debounceUpdateDeviceData(device_id: string) {
      let device = this.user.devices.find((x: any) => x.id == device_id);
      // eslint-disable-next-line
      const timeoutId = window.setTimeout((): any => {}, 0);
      for (let id = timeoutId; id >= 0; id -= 1) {
        window.clearTimeout(id);
      }

      setTimeout(() => {
        this.updateDeviceData(device);
        this.user.devices.find((x: any) => x.id == device.id).friendly_name =
          device.friendly_name;
      }, 1000);
    },

    canEdit(device: any) {
      if (
        typeof device.shared == undefined ||
        !device.shared ||
        (device.shared && device.permissions == "R/W")
      ) {
        return true;
      }
      return false;
    },
    start(event: any, parameter: any) {
      this.row = event.target;
      this.draggingParameter = parameter;
      this.originalDisplayOrder = parameter.display_order;
      this.dragoverId = parameter.id;
    },
    async dragover(event: any, parameter: any) {
      if (this.dragoverId != parameter.id) {
        this.dragoverId = parameter.id;
        var e = event;
        e.preventDefault();

        if (this.draggingParameter.id != parameter.id) {
          this.draggingParameter.display_order = parameter.display_order;
          this.draggingParameter.group = parameter.group;
        }
      }
    },
    updateParameterAfter(device: any, parameter: any, skipRecursion = false) {
      let paramsToIncrement: any = [];
      let displayOrder =
        parameter.display_order < this.originalDisplayOrder ||
        this.originalGroup != parameter.group
          ? parameter.display_order <= 0
            ? 1
            : parameter.display_order
          : this.originalDisplayOrder;

      ["PAR", "ACT"].forEach((type) => {
        paramsToIncrement.push(
          ...device[type].filter(
            (x: any) =>
              (!x.hidden &&
                x.display_order >= displayOrder &&
                x.group == parameter.group) ||
              x.id == parameter.id
          )
        );
      });
      paramsToIncrement.sort(this.compare).forEach((param: any, index: any) => {
        param.display_order = displayOrder + index;
      });

      if (!skipRecursion && this.originalGroup != parameter.group) {
        this.updateParameterAfter(
          device,
          { display_order: this.originalDisplayOrder, groups: this.originalGroup },
          true
        );
      }
    },
    async end(device: any) {
      await this.updateParameterAfter(device, this.draggingParameter);
      await this.updateDeviceData(device);
    },
    async textAreaAdjust(event: any, resize = false) {
      event.target.style.height = "1rem";
      // event.target.style.height = event.target.scrollHeight + "px";

      if (resize) {
        this.$nextTick(async () => {
          await this.maxSizeTexarea(event.target);
        });
      }
    },
    async maxSizeTexarea(element: any) {
      element.style.height = element.scrollHeight + "px";
    },
    isJsonString(str: any) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    arraysEqual(a: any, b: any) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
  },
});
