import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from "axios";

import { createPinia } from 'pinia'
import { useAuthStore } from './stores/auth';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'

import { i18n } from './i18n'
import './index.css'

import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

// eslint-disable-next-line
const OpenLayersMap = require('vue3-openlayers')
import 'vue3-openlayers/dist/vue3-openlayers.css'


/* import specific icons */
import { faEye, faEyeSlash, faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { faEllipsisV, faXmark, faCamera, faPaperclip, faChevronDown, faChevronUp, faPlus, faBan } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faEye, faEyeSlash, faEllipsisV, faXmark, faCamera, faPaperclip, faChevronDown, faChevronUp, faPlus, faBan, faPenToSquare, faTrashCan)

// axios.defaults.withCredentials = true

import uPlot from 'uplot';
import UplotVue from 'uplot-vue';
import 'uplot/dist/uPlot.min.css';

axios.interceptors.response.use(undefined, function (error) {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            const authStore = useAuthStore(pinia)

            originalRequest._retry = true;
            // authStore.LogOut

            // console.log("LOGOUTTTT INTECEPTOR");

            if (router.currentRoute.value.meta.requiresAuthentication && window.location.href != '/login') {
                return router.push('/login')
                // window.location.href != '/login'
            }

            // return Promise.reject(error);
        }
        if (error.response.status != 401) {
            return Promise.reject(error);
        }
    }
})


const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
    cache,
    uri: 'http://www.iot-center.click/graphql',
})

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
})

const app = createApp(App)


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

/* add font awesome icon component */
app.component("font-awesome-icon", FontAwesomeIcon)
app.component("UplotVue", UplotVue)

/* add dynamic scroller component */
app.use(VueVirtualScroller)
// app.use(, uPlot)
app.use(pinia)
app.use(router)
app.use(apolloProvider)
app.use(i18n)
app.use(OpenLayersMap)

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore(pinia)

    if (to.matched.some(record => record.meta.requiresAuthentication)) {
        if (authStore.userAuthenticated) {
            next()
            return
        }
        next('/login')
    } else {
        if (authStore.userAuthenticated && to.matched.some(record => record.path == '/login' || record.path == '/register')) {
            next('/')
        }
        next()
    }
})


app.mount('#app')
