import { defineStore } from 'pinia'
// import axios from 'axios'

// the first argument is a unique id of the store across your application
export const useAuthStore = defineStore('auth', {
  actions: {
    async LogIn(value: any): Promise<void> {
      this.user = value.user;
    },
    LogOut() {
      this.user = {}
      this.access_token = null
    }
  },
  getters: {
    userAuthenticated: (state) => state.access_token != null,
  },
  state: () => {
    return {
      access_token: null,
      client_connected: null,
      // all these properties will have their type inferred automatically
      user: {} as any,
    }
  },
  persist: true
})