<template>
  <div class="popup-overlay">
    <span
      class="dismiss-background"
      style="z-index: -1 !important"
      @click.prevent="$emit('dismiss')"
    />
    <div class="data-popup-editor mt-24">
      <button @click.prevent="$emit('dismiss')" class="close-btn">
        <font-awesome-icon class="icon-close" :icon="['fas', 'xmark']" />
      </button>
      <!-- <font-awesome-icon class="icon-close" :icon="['fas', 'xmark']" />
      <button class="close-btn" @click.prevent="$emit('dismiss')">
        <i class="icon-close"></i> -->
      <!-- </button> -->
      <h4>Calculated value parameter</h4>
      <h5>CUSTOM</h5>

      <div class="wrapper">
        <label for="">Name</label>
        <slot name="name-input">
          <input type="text" name="" id="" value="" placeholder="Enter name"
        /></slot>
      </div>

      <div class="wrapper">
        <label for="">Parent group</label>
        <slot name="parent-group-input">
          <select name="lang" required>
            <option value="Air_measurements">Air measurements</option>
            <option value="Soil_measurements">Soil measurements</option>
          </select></slot
        >
      </div>

      <div>
        <label for="">Calculation</label>
        <p class="p-3">
          Enter the equation below to create a new calculated value parameter.
        </p>
        <slot name="equation-input">
          <input class="h-16" type="text" name="" id=""
        /></slot>
      </div>

      <!-- accordions -->
      <div class="accordion mx-2 mt-5">
        <!-- parameters accordion -->
        <h5 class="accordion-title">
          Parameters <i class="icon-dropdown" @click="$emit('open-close-parameters')"></i>
        </h5>
        <div id="accordion-parameters-content" class="accordion-content">
          <slot name="parameters-list"> <ul class="tags mb-5"></ul></slot>
        </div>

        <!-- functions accordion -->
        <h5 class="accordion-title">
          Functions <i class="icon-dropdown" @click="$emit('open-close-functions')"></i>
        </h5>
        <div id="accordion-functions-content" class="accordion-content">
          <slot name="functions-list"> <ul class="tags mb-5"></ul></slot>
        </div>

        <div><slot name="errors"> </slot></div>
      </div>
    </div>
    <!-- end of accordions -->

    <!-- when user adds new cvp this div is shown -->
    <div class="m-6 text-center z-20">
      <slot name="modal-button">
        <button class="btn" @click.prevent="$emit('save')">Add parameter</button>
      </slot>
    </div>

    <!-- if user would like to edit already created cvp then this div is shown -->
    <!-- <div class="m-6 text-center">
            <button class="btn mb-5">Save parameter</button>
            <a class="block text-red-500 hover:text-red-500" href="">Delete parameter</a>
          </div> -->
  </div>
</template>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 350px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  z-index: 9999;
  font-family: Helvetica, Arial, sans-serif;
}
.dismiss-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-footer {
  padding-bottom: 20px;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-cancel-button {
  float: left;
  color: rgb(228, 2, 2);
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
