<template>
  <!-- app navigation -->
  <div class="bottom-app-nav">
    <ul>
      <!-- <li @click.prevent="$router.push('/device-details')">
        <a href="" :class="$route.path.includes('/device-details/') ? 'active' : ''"
          ><i class="icon-details"></i
        ></a>
      </li> -->
      <li
        @click.prevent="
          $route.params.device_id
            ? $router.push('/device-history/' + $route.params.device_id)
            : $router.push('/device-history/null')
        "
      >
        <a href="" :class="$route.path.includes('/device-history/') ? 'active' : ''"
          ><i class="icon-analytics"></i
        ></a>
      </li>
      <li
        @click.prevent="
          $route.params.device_id
            ? $router.push('/device-media/' + $route.params.device_id)
            : $router.push('/device-media/null')
        "
      >
        <a href="" :class="$route.path.includes('/device-media/') ? 'active' : ''"
          ><i class="icon-media"></i
        ></a>
      </li>
      <li>
        <a
          @click.prevent="
            $route.params.device_id
              ? $router.push('/device-info/' + $route.params.device_id)
              : $router.push('/device-info/null')
          "
          :class="$route.path.includes('/device-info/') ? 'active' : ''"
          ><i class="icon-info"></i
        ></a>
      </li>
      <li>
        <a
          @click.prevent="
            $route.params.device_id
              ? $router.push('/device-settings/' + $route.params.device_id)
              : $router.push('/device-settings/null')
          "
          :class="$route.path.includes('/device-settings/') ? 'active' : ''"
          ><i class="icon-settings"></i
        ></a>
      </li>
    </ul>
  </div>
</template>
