import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationComponent = _resolveComponent("NavigationComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BottomAppNavigation = _resolveComponent("BottomAppNavigation")!
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.userAuthenticated)
          ? (_openBlock(), _createBlock(_component_NavigationComponent, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_router_view)
        ]),
        (
        _ctx.userAuthenticated &&
        _ctx.$route.path != '/' &&
        _ctx.$route.path != '/notifications' &&
        _ctx.$route.path != '/my-profile' &&
        _ctx.$route.path != '/add-device'
      )
          ? (_openBlock(), _createBlock(_component_BottomAppNavigation, { key: 1 }))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createBlock(_component_LoadingAnimation, {
        key: 1,
        class: "py-24"
      }))
}