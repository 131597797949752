import { createI18n } from './vue-i18next'
import i18nextMod from 'i18next'

import * as en from './locales/en/common.json'
import * as slo from './locales/slo/common.json'

export const i18next = i18nextMod

export const i18nextPromise = i18next
  // .use(LanguageDetector)
  // .use(i18nextHttpBackend)
  .init({
    debug: true,
    fallbackLng: 'en',
    // backend: {
    //   loadPath: './locales/{{lng}}/{{ns}}.json'
    // },
    lng: 'slo',
    // preload: ['slo', 'en'],
    // initImmediate: false,
    supportedLngs: ['slo', 'en'],
    ns: ['common'],
    defaultNS: 'common',
    // load: 'languageOnly',
    resources: {
      en,
      slo
    }
  });

export const i18n = createI18n(i18next);